import React, { useContext } from "react";
import { Link } from "react-router-dom";

import UserContext from "../UserContext";


function Home() {
    const [user, setUser] = useContext(UserContext);

    const logout = () => {
        setUser(null);
        localStorage.removeItem("token");
    };

    if (user) return (<>
        <p>hello { user }</p><br/>
        <button onClick={ logout }>logout</button>
    </>);
    
    return (<>
        <Link to="/login">login</Link><br/>
        <Link to="/register">register</Link>
    </>)
}


export default Home;
