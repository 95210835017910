import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../UserContext";

function Login() {
    const Navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);
    const [curName, setCurName] = useState("");
    const [curPass, setCurPass] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const handleLogin = () => {
        const req = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ name: curName, pass: curPass })
        };

        fetch(`${process.env.REACT_APP_BACKEND_API}/user/login`, req)
        .then(res => res.text().then(text => ({ status: res.status, text })))
        .then(res => {
            if (res.status === 200) {
                localStorage.setItem("token", res.text);
                setUser(curName);
                Navigate("/");
            }
            else if (res.status === 401) setErrMsg(res.text);
            else console.log(res.text);
        })
        .catch(err => console.log(err));
    };

    return (<>
        <h3>login</h3>
        <input type="text" placeholder="username" onChange={ e => setCurName(e.target.value) } /><br/>
        <input type="password" placeholder="password" onChange={ e => setCurPass(e.target.value) } /><br/>
        <p>{ errMsg }</p>
        <button onClick={ handleLogin }>Submit</button>
    </>);
}

export default Login;
