import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import UserContext from "../UserContext";

import Home from "./Home";
import Register from "./Register";
import Login from "./Login";
import NotFound from "./NotFound";


function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) return;

        const req = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        };

        fetch(`${process.env.REACT_APP_BACKEND_API}/user`, req)
        .then(res => res.text())
        .then(res => setUser(res))
        .catch(err => console.log(err));
    }, []);

    return (<>
        <BrowserRouter>
            <Link to="/"><h1>Izakaya</h1></Link>
            <UserContext.Provider value={ [user, setUser] }>
                <Routes>
                    <Route path="/" element={ <Home /> } />
                    <Route path="/register" element={ <Register /> } />
                    <Route path="/login" element={ <Login /> } />
                    <Route path="*" element={ <NotFound /> } />
                </Routes>
            </UserContext.Provider>
        </BrowserRouter>
    </>);
}


export default App;
