import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserContext from "../UserContext";


function Register() {
    const Navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);
    const [curName, setCurName] = useState("");
    const [curPass, setCurPass] = useState("");
    const [curPassConf, setCurPassConf] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const handleLogin = () => {
        if (curPass !== curPassConf) {
            setErrMsg("Passwords do not match");
            return;
        }

        const req = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ name: curName, pass: curPass })
        };

        fetch(`${process.env.REACT_APP_BACKEND_API}/user/register`, req)
        .then(res => res.text().then(text => ({ status: res.status, text })))
        .then(res => {
            if (res.status === 200) {
                localStorage.setItem("token", res.text);
                setUser(curName);
                Navigate("/");
            }
            else if (res.status === 409) setErrMsg(res.text);
            else console.log(res.text);
        })
        .catch(err => console.log(err));
    };

    return (<>
        <h3>Register</h3>
        <input type="text" placeholder="username" onChange={ e => setCurName(e.target.value) } /><br/>
        <input type="password" placeholder="password" onChange={ e => setCurPass(e.target.value) } /><br/>
        <input type="password" placeholder="confirm" onChange={ e => setCurPassConf(e.target.value) } /><br/>
        <p>{ errMsg }</p>
        <button onClick={ handleLogin }>Submit</button>
    </>);
}


export default Register;